import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";

import CallCount from "../components/call-count";
import PageLayout from "../components/page-layout";
import HowItWorksSection from "../components/sections/how-it-works";
import TeamSection from "../components/sections/team";
import Testimonials from "../components/testimonials";

import smiley from "../images/upside-down-face.png";

import * as style from "./index.module.css";

const start = () =>
  (function (d, s, id) {
    var js;
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://embedsocial.com/cdn/ht.js";
    d.getElementsByTagName("head")[0].appendChild(js);
  })(document, "script", "EmbedSocialHashtagScript");

const IndexPage = () => {
  useEffect(() => {
    start();
  }, []);

  return (
    <PageLayout
      title="Call A Colleague"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={smiley}
    >
      <section id="above-fold" className={style.aboveFold}>
        <StaticImage
          src="../images/upside-down-face.png"
          width={50}
          height={50}
          alt="smiling face"
        />
        <h1>Call A Colleague</h1>
        <p className={style.subtitle}>
          Solidarity of professionals VS. crazy world leaders
        </p>

        <div className={style.callCountDisplay}>
          <div>
            <CallCount />
          </div>
          <div>calls booked</div>
        </div>

        <ul>
          <li>
            Are you a professional who in <b>any</b> way suffered from the
            Russian war in Ukraine?
          </li>
          <li>Do you have questions about working in Europe?</li>
          <li>Talk to a colleague who already works in a similar position!</li>
        </ul>

        <br />
        <p>
          <Link to="/step1" className="cac-button">
            Book FREE call
          </Link>
        </p>
      </section>

      <HowItWorksSection
        title="How it works?"
        items={[
          {
            title: "Step 1",
            text: "Pick your professional area.",
          },
          {
            title: "Step 2",
            text: "Pick your mentor and a time slot.",
          },
          {
            title: "Step 3",
            text: "Tell us how to contact you and request a call.",
          },
          {
            title: "Step 4",
            text: "Find a call confirmation card in your mail box. Use the link from the email if you need to reject a scheduled call.",
          },
          {
            title: "Keep in mind",
            text: "Mentors are volunteers who make time to talk to you (some even arrange for babysitting). Please be there for the call or cancel it up-front.",
          },
        ]}
      />

      <section id="testimonials" className={style.testimonialSection}>
        <h2>We helped</h2>
        <Testimonials />
      </section>

      <TeamSection />

      <section className={style.embedSection}>
        <h2>More career advice</h2>

        <div
          dangerouslySetInnerHTML={{
            __html:
              '<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="//lightwidget.com/widgets/0ad6ba9088a25f31868d3132c551fa7a.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>',
          }}
        />
      </section>
    </PageLayout>
  );
};

export default IndexPage;
